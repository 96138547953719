import { render, staticRenderFns } from "./XBox.vue?vue&type=template&id=32453fdc&scoped=true"
import script from "./XBox.vue?vue&type=script&lang=ts"
export * from "./XBox.vue?vue&type=script&lang=ts"
import style0 from "./XBox.vue?vue&type=style&index=0&id=32453fdc&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32453fdc",
  null
  
)

export default component.exports